const defaultTheme = () => {
  return {
    theme_config: {
      consentScreen: {
        heading: {
          color: "#000000",
          fontSize: "18px",
        },
        description: {
          color: "#000000",
          fontSize: "14px",
        },
      },
      loader: {
        url: "",
        textStyle: {
          color: "#1d2c42",
          fontSize: "14px",
        },
      },
      camera: {
        ovalBackgroundColor: {
          color: "#ffffff",
        },
        ovalCameraInstructionText: {
          color: "#000000",
          fontSize: "20px",
        },
      },
      instructionDeclineScreen: {
        instructionHeadingText: {
          color: "#000000",
          fontSize: "18px",
        },
        instructionSubheadingText: {
          color: "#000000",
          fontSize: "14px",
        },
        instructionText: {
          color: "#000000",
          fontSize: "14px",
        },
        declineText: {
          color: "#D11010",
          fontSize: "12px",
        },
      },
      logo: {
        url: "",
      },
      buttons: {
        background: "#334acc",
        color: "#FAFAFA",
        fontSize: "14px",
        borderColor: "#334acc", // Your desired border color
      },
    },
  };
};

const state = {
  defaultTheme: defaultTheme(), // Invoke the function to set the initial default theme
  customTheme: {},
  isLoading: false,
};
const mutations = {
  resetToDefaultTheme(state) {
    state.customTheme = JSON.parse(JSON.stringify(defaultTheme()));
  },
  updateStyle(state, { path, value }) {
    if (typeof path !== "string") {
      console.error("Invalid path:", path);
      return;
    }
    const keys = path.split(".");
    let current = state.customTheme;
    for (let i = 0; i < keys.length - 1; i++) {
      if (current[keys[i]] === undefined) {
        current[keys[i]] = {};
      }
      current = current[keys[i]];
    }
    current[keys[keys.length - 1]] = value;
  },
  setThemeFromAPI(state, themeData) {
    // Ensure themeData is properly structured
    if (themeData && themeData.data) {
      state.customTheme = {
        theme_config: themeData.data
      };
    }
  },
  SET_LOADING(state, value) {
    state.isLoading = value;
  },
};

const actions = {
  updateCustomization({ commit }, { path, value }) {
    commit("updateStyle", { path, value });
  },
  resetCustomization({ commit }) {
    commit("resetToDefaultTheme"); // Ensure the mutation name is correct
  },
  setAPITheme({ commit }, themeData) {
    commit('setThemeFromAPI', themeData);
  },
  setLoading({ commit }, value) {
    commit('SET_LOADING', value);
  },
};
const getters = {
  getCustomization: (state) => {
    const mergedTheme = JSON.parse(JSON.stringify(defaultTheme()));
    function mergeObjects(target, source) {
      for (let key in source) {
        if (typeof source[key] === "object" && source[key] !== null) {
          if (!target[key]) target[key] = {};
          mergeObjects(target[key], source[key]);
        } else if (source[key] !== undefined) {
          target[key] = source[key];
        }
      }
    }
    mergeObjects(mergedTheme, state.customTheme);
    return mergedTheme;
  },
  isLoading: state => state.isLoading,
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
