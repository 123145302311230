import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import login from "./auth/login.module";
import style from './auth/style.module'
import userManagement from "./user-management/user-management.module";
import ApiService from "@/services/Api";
import {
  showErrorMessage
} from "@/utils/showError";
Vue.use(Vuex);

const getDefaultState = () => {
  return {
    token: "",
    userEmail: "",
    userName: "",
    userImage: null,
    userRole: "",
    userPermissions: {},
    statsData: null,
    otpSignature: null, 
  };
};

export default new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    login,
    userManagement,
    style,
  },

  state: getDefaultState(),

  mutations: {
    SET_USER_PERMISSIONS(state, permissions) {
      if (
        permissions &&
        permissions[0] &&
        Array.isArray(permissions[0]?.permissions)
      ) {
        const tempPermissions = permissions[0].permissions;
        const hashMap = tempPermissions.reduce((total, currValue) => {
          if (!total[currValue.name]) {
            total[currValue.name] = true;
          }
          return total;
        }, {});
        state.userPermissions = hashMap;
      } else state.userPermissions = {};
    },
    LOGOUT(state) {
      Object.assign(state, getDefaultState());
      localStorage.removeItem("vuex");
      localStorage.removeItem("token");
      localStorage.removeItem("userPermissions");
      localStorage.clear();
    },
    SET_STATS(state, statsData) {
      state.statsData = statsData;
    },
    SET_OTP_SIGNATURE(state, otpSignature) {
      state.otpSignature = otpSignature; // Store the OTP signature
    },
    RESET_STORE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    login({ commit }, payload) {
      commit("SET_USER_PERMISSIONS", payload.userRole);
    },
    logout({ commit, dispatch }, payload) {
      dispatch("resetStoreState");
      commit("LOGOUT", payload);
    },
    GET_STATS({ commit }, payload) {
      return new Promise((resolve, reject) => {
        ApiService.PostRequest("merchant/transaction-counts", payload)
          .then((res) => {
            commit("SET_STATS", res.data.transaction_counts);
            resolve(res);
          })
          .catch((err) => {
            showErrorMessage(err);
            reject(err);
          });
      });
    },
    resetStoreState({ commit }) {
      commit("RESET_STORE");
    },
    setOtpSignature({ commit }, otpSignature) {
      commit('SET_OTP_SIGNATURE', otpSignature); // Commit the mutation
    },
  },

  getters: {
    tokenGetter(state) {
      return state.token;
    },
    getStats(state) {
      return state.statsData;
    },
    permissions(state) {
      return state.userPermissions;
    },
    getOtpSignature: (state) => state.otpSignature, // Getter to access OTP signature
  },
});
