<template>
  <v-app>
    <v-main>
      <snack-bar></snack-bar>
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
  </v-app>
</template>

<script>
import SnackBar from "./components/dashboard/notifications/SnackBar.vue";
export default {
  name: "App",
  components: {
    SnackBar,
  },
  computed:{},
  mounted(){
    navigator?.permissions
      ?.query({ name: "camera" })
      .then(function (permissionStatus) {
        permissionStatus.onchange = function () {
          if (permissionStatus.state == "granted") {
            window.location.reload();
          } else {
            window.location.reload();
          }
        };
      });
  }
};
</script>
